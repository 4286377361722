import React from 'react';
import headerVideo from '../../assets/landing/fondazioneVeronesi/bambina-senza-loghi.mp4';
import introVideo from '../../assets/landing/fondazioneVeronesi/intro.mp4';
import logoCare from '../../assets/logo/logo1.webp';
import logoFV from '../../assets/landing/fondazioneVeronesi/Logo-Fondazione-con-nastro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fiocco from "../../assets/landing/fondazioneVeronesi/Bimba-con-fiocco.webp";
import ricercatrice from "../../assets/landing/fondazioneVeronesi/ricercatrice.png";
import { Helmet } from 'react-helmet';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";


function arrowFV (){
    const startContent = document.querySelector("#startContent");
    const topOfElement = startContent.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
        top: topOfElement,
        behavior: "smooth",
    });
}

function donation (){
    window.open('https://insieme.fondazioneveronesi.it/project/la-speranza-e-una-luce-doro/?fbclid=IwAR2CY3mUddBleWLF46Q8_4idqm0uPgzvXl8XOQAtTVuZn8qpl-dRhKxC4tU', '_blank');
}

function videoYT (){
    window.open('https://youtu.be/52k35k4r8uI?feature=shared', '_blank');
}

function FondazioneVeronesi() {
  return (
    <>
        <Helmet>
            <title>Careisgold SpA: Fondazione Veronesi</title>
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
            </style>
        </Helmet>
        <div className='d-none'><Navbar/></div>
        <div className="fullscreen-video-fv">
        <video playsInline autoPlay muted loop>
            <source src={headerVideo} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="overlay-content-fv">
            <h1 className="fw-bold text-light h1-fv outline-text">Il nostro oro brilla con una luce diversa, <br/>quella della <em className="">speranza</em></h1>
            <p className="text-center text-light fs-3 mt-md-5 outline-text">Diventa un eroe nella vita di un bambino malato di tumore</p>
            <div className="text-center">
                <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="display-1 text-light arrow-icon-fv mt-xxl-5n" onClick={arrowFV} />
            </div>
        </div>
        <div className="overlay-logo-fv">
            <img src={logoCare} className="img-fluid" alt="logo careisgold s.p.a." />
            {/* <p className="text-uppercase fw-bold text-end my-3 opening-animation-fv">a sostegno di</p> */}
            <img src={logoFV} className="img-fluid opening-image-fv" alt="logo careisgold s.p.a." />
        </div>
        </div>
        <div className="container" id="startContent">
            <div className="row d-flex justify-content-center align-items-center text-center py-5 px-4">
                <p className="fw-semibold fs-5">Careisgold offre alle famiglie la possibilità di proteggere i propri risparmi.<br/>
                    La nostra mission è prenderci cura delle persone, soprattutto delle famiglie.
                    Per questo abbiamo deciso di mettere al centro la tutela dei bambini malati.</p>
                <p className="fw-semibold text-uppercase fs-4 mb-5 mt-4">Questi bambini sono anche nostri, sono il nostro futuro.</p>
                <div className="col-12 text-center">
                    <button className="btn btn-warning rounded-pill px-5 py-3 fs-5 fw-semibold" onClick={donation}>Dona ora</button>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-white pb-5">
            <div className="row d-flex justify-content-center align-items-top">
                <div className="col-12 col-lg-6 col-xl-4 text-center mt-5">
                    <img src={logoCare} className="img-fluid w-75" alt="logo careisgold s.p.a." />
                    {/* <p className="text-uppercase fw-bold text-center my-3">a sostegno di</p> */}
                    <img src={logoFV} className="img-fluid w-75 mt-5 mb-3" alt="logo careisgold s.p.a." />
                    <p className="text-uppercase fw-bold text-center my-3 fs-5">insieme nella lotta contro i tumori pediatrici</p>
                    <img src={fiocco} className="img-fluid" alt="bambina con fiocco in mano"/>
                </div>
                <div className="col-11 col-lg-6 col-xl-4 pt-md-5 text-start">
                    <p className="fs-5 my-md-5">Ogni anno, in Italia, si ammalano di cancro circa 1.400 bambini fino a 14 anni e circa 800 adolescenti tra i 15 e i 19 anni.</p>
                    <p className="fs-5 my-md-5">Le neoplasie pediatriche, infatti, rappresentano ancora oggi la prima causa di morte per malattia nei bambini e hanno un impatto drammatico sulle famiglie.</p>
                    <p className="fs-5 my-md-5"><span className="fw-bold">Careisgold ha deciso di sostenere la ricerca scientifica nel campo dell’oncologia pediatrica</span>, per migliorare la vita di questi bambini e delle loro famiglie.</p>
                    <p className="fw-bold fs-5 my-md-5">Il tuo aiuto è fondamentale.
                    Con il tuo sostegno possiamo fare la differenza.</p>
                    <div className="col-12 text-center mt-5">
                        <button className="btn btn-warning rounded-pill px-5 py-3 fs-5 fw-semibold" onClick={donation}>Dona ora</button>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="container-fluid bg-light pb-5">
            <div className="row d-flex justify-content-center align-items-center text-center px-4">
                <div className="col-12 col-md-6">
                    <p className="fw-semibold fs-5 py-5">Per questo Careisgold ha fortemente desiderato e voluto sostenere la ricerca scientifica di Fondazione Veronesi nel campo dell’oncologia pediatrica.</p>
                    <div className="col-12 text-center">
                        <button className="btn btn-warning rounded-pill px-5 py-3 fs-5 fw-semibold" onClick={donation}>Dona ora</button>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="container-fluid bg-light pt-5">
            <div className="row d-flex justify-content-center align-items-top">
            <p className="font-great-vibes text-center">La speranza è una luce d'oro</p>
                <div className="col-11 col-xl-6 col-xxl-4 pt-md-5 text-start">
                    <p className="fs-5 my-md-5">Cos’è la speranza?</p>
                    <p className="fs-5 my-md-5">
                        Per i bambini malati di tumore e
                        per le loro famiglie, la speranza è la cura.
                        E la cura viene dalla ricerca.
                        Quindi, la speranza è la ricerca.</p>
                    <p className="fs-5 my-md-5">
                        Careisgold è al fianco di Fondazione Veronesi
                        per sostenere la ricerca scientifica sui tumori
                        dell’infanzia e dell’adolescenza.
                    </p>
                    <p className="fs-5 my-md-5 fw-bold">
                        Sostenere la ricerca sui tumori pediatrici
                        è sostenere i bambini e le loro famiglie.
                    </p>
                </div>
                <div className="col-12 col-xxl-6 text-center mt-5">
                    <div className="row d-flex justify-content-center align-items-start">
                        <div className="col-12 col-xl-6 col-xxl-10">
                            <img src={ricercatrice} className="img-fluid" alt="logo careisgold s.p.a." />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-items-start bg-white py-5">
                <p className="text-uppercase fw-bold text-center mb-5 px-5 fs-4">Perché abbiamo scelto fondazione veronesi:</p>
                <div className="col-12 col-md-6 col-xl-4 col-xxl-4">
                    <ul className="m-0 list-unstyled">
                        <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-user-doctor" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            2.334 ricercatori finanziati in 179 Istituti e Università
                        </li>
                        <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-microscope" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            154 progetti di ricerca sostenuti
                        </li>
                        {/* <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-hand-holding-heart" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            70.000 donatori e 2200 aziende sostenitrici
                        </li>
                        <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-handshake-angle" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            30 delegazioni, 2000 volontari
                        </li> */}
                    </ul>
                </div>
                <div className="col-12 col-md-6 col-xl-4 col-xxl-4">
                    <ul className="m-0 list-unstyled">
                        {/* <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-microscope" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            154 progetti di ricerca sostenuti
                        </li> */}
                        <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-book-medical" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            18 protocolli di cura in oncologia pediatrica con AIEOP
                        </li>
                        <li className="p-3 fs-5 d-flex justify-content-start align-items-center">
                            <FontAwesomeIcon icon="fa-solid fa-building" className="me-3 bg-dark text-light p-3 rounded-circle"/>
                            3 piattaforme di ricerca e cura
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light">
            <div className="row d-flex justify-content-center align-items-center text-center px-4 py-5">
                <div className="col-12 col-md-6">
                    <p className="fw-semibold fs-5 pb-4">CON L’ORO PURO FISICO DA INVESTIMENTO DI CAREISGOLD SOSTIENI LA RICERCA</p>
                    <div className="col-12 text-center">
                        <button className="btn btn-warning rounded-pill px-5 py-3 fs-5 fw-semibold" onClick={donation}>Dona ora</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="fullscreen-video-fv">
            <video playsInline autoPlay muted loop>
                <source src={introVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay-bg-fv">
            </div>
            <div className="overlay-intro-fv">
                    <p className="fw-bold text-light fs-1">La sua forza è stata la nostra forza</p>
                    <button onClick={videoYT} className="text-center btn btn-warning text-dark fs-3 mt-md-5 border-0 rounded-pill px-5 py-3">Ascolta la storia di Anna</button>
            </div>
        </div>
        <Footer/>
    </>
  );
}

export default FondazioneVeronesi;
