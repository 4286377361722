import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/logo/logo1.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'



const Footer = () => {

    const handleFooterLinkClick = () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      };


  return (
    <div>
      <Helmet>
        <script type="text/javascript">
          {`
            _linkedin_partner_id = "7166057";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </script>
        <script type="text/javascript">
          {`
            (function(l) {
              if (!l) {
                window.lintrk = function(a, b) {
                  window.lintrk.q.push([a, b]);
                };
                window.lintrk.q = [];
              }
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";
              b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);
            })(window.lintrk);
          `}
        </script>
      </Helmet>
        <footer className="footer bg-black py-4">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-top">
                    <div className="col-11 col-lg-2 mt-lg-4 d-flex align-items-center">
                        <img src={logo} className="img-fluid custom-logo-size" alt="logo"/>
                    </div>
                    <div className="mt-4 col-md-6 col-lg-4 col-xl-3 text-light text-center text-lg-start">
                        <p className="m-0 fw-bold text-default">Careisgold SpA</p>
                        <p className="m-0">Via Monte Baldo 14/D, Villafranca di Verona</p>
                        <p className="m-0">C.F./P.Iva 04598560235 - REA VR-433912</p>
                        <p className="m-0">CAP. SOCIALE € 2.000.000,00 i.v. careisgoldspa@pec.careisgold.it</p>
                        <p className="m-0">Operatore Professionale Oro - Codice n° 5008578</p>
                    </div>
                    <div className="mt-3 mt-md-4 col-md-5 col-lg-2 text-center text-lg-start">
                        <p className="m-0 fw-bold text-default fs-6">AZIENDA</p>
                        <ul className="list-unstyled">
                            <li className="" >
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/gruppo">Il Gruppo</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/staff">Lo Staff</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/accreditamenti-certificazioni">Accreditamenti e Certificazioni</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/perche-scegliere-careisgold">Perché Cig</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://careisgold.it/pdf/codice-etico.pdf">Codice etico</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/notizie/?section=rassegna-stampa">Rassegna Stampa</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3 mt-lg-4 col-md-6 col-lg-2 text-center text-lg-start">
                        <p className="m-0 fw-bold text-default fs-6">PRODOTTI</p>
                        <ul className="list-unstyled">
                            <li className="" >
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/servizi">Servizi</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/servizi">La destinazione dell'Oro</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/certificazione-good-delivery">Certificazione Good Delivery</Link>
                            </li>
                            {/* <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/kinegram">Il sistema Kinegram</Link>
                            </li> */}
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/banco-metalli">Banco Metalli</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3 mt-lg-4 col-md-5 col-lg-2 text-center text-lg-start">
                        <p className="m-0 fw-bold text-default fs-6">COLLABORA CON NOI</p>
                        <ul className="list-unstyled">
                            <li className="" >
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/collabora-con-noi?activeTab=0">Divisione Prime</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/collabora-con-noi?activeTab=1">Divisione Private</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/collabora-con-noi?activeTab=2">Divisione Corporate</Link>
                            </li>
                            <li className="">
                                <Link className="text-light text-decoration-none" target='_blank' to="https://www.careisgold.it/oro-prestige">Oro Prestige</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center my-md-3 mb-5">
                        <div className="col-md-6 col-xl-4 my-3 text-center">
                            <div className="container">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-md-4 col-12">
                                        <p className="mb-md-0 mb-3 fw-bold text-default fs-6 m-0 p-0">SEGUICI SU</p>
                                    </div>
                                    <div className="col-md-2 col-2 text-center">
                                    <a className="btn btn-dark btn-social" href="https://www.facebook.com/careisgoldspa/" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FontAwesomeIcon className="fs-5 text-light" icon={faFacebookF}/></a>

                                    </div>
                                    <div className="col-md-2 col-2 text-center">
                                    <a className="btn btn-dark btn-social" href="https://www.instagram.com/careisgoldspa/?igshid=15rizhw3869f2" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FontAwesomeIcon className="fs-5 text-light" icon={faInstagram}/></a>

                                    </div>
                                    <div className="col-md-2 col-2 text-center">
                                    <a className="btn btn-dark btn-social" href="https://www.linkedin.com/company/careisgold-spa" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><FontAwesomeIcon className="fs-5 text-light" icon={faLinkedinIn}/></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-xl-3 text-md-start text-center">
                            <a className="link-light text-decoration-none me-3" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/95086304">Privacy Policy</a>
                            <a className="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/95086304/cookie-policy">Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer